import { quotes } from "../assets";

const FeedbackCard = ({ content, name, title, img }) => (
  
  <div className="flex justify-between flex-col px-10 py-6 rounded-[20px] mb-[0px] max-w-[370px] md:mr-10 sm:mr-5 mr-0 my-5 mb-[0px] mt-[0px] feedback-card">
    <div className="flex flex-col">
        <img 
          src={quotes}
          alt="double_quotes"
          className="w-[35.6px] h-[22.6px] object-contain mb-[0px] mt-[0px]"
        />
      </div>
    <p className="font-poppins font-normal text-[18px] leading-[32.4px] text-white my-10 mb-[10px]">
      {content}
    </p>

    <div className="flex flex-row">
      {/* <img src={img} alt={name} className="w-[48px] h-[48px] rounded-full" /> */}
      <div>
      <div className="flex flex-col items-end flex-end">
        <img 
          src={quotes}
          alt="double_quotes"
          className="w-[35.6px] h-[22.6px] object-contain mb-[30px] ml-[200px] mt-[0px]"
        />
      </div>

        <h4 className="font-poppins font-semibold text-[20px] leading-[32px] text-white">
          {name}
        </h4>
        <p className="font-poppins font-normal text-[16px] leading-[24px] text-dimWhite">
          {title}
        </p>
      </div>
    </div>
  </div>
);


export default FeedbackCard;
