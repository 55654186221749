import styles from "../style";
import { robot } from "../assets";
import GetStarted from "./GetStarted";

const Hero = () => {
  return (
    <section id="home" className={`flex md:flex-row flex-col ${styles.paddingY}`}>
      <div className={`flex-1 ${styles.flexStart} flex-col xl:px-0 sm:px-16 px-6`}>
        {/* <div className="flex flex-row items-center py-[6px] px-4 bg-discount-gradient rounded-[10px] mb-4">
          <img src={discount} alt="discount" className="w-[32px] h-[32px]" />
          <p className={`${styles.paragraph} ml-2`}>
            <span className="text-white">Free</span> to {" "}
            <span className="text-white">access</span>
          </p>
        </div> */}

        <div className="flex flex-row justify-between items-center w-full">
          <h1 className="flex-1 font-poppins font-semibold ss:text-[40px] text-[35px] text-white">
            Empower your <br/>
            <span className="text-gradient">teams with next</span><br/>
            gen AI tools
          </h1>
          {/* <div className="ss:flex hidden mr-[-430px] mb-[-80px]">
            <GetStarted />
          </div> */}
        </div>

        <p className={`${styles.paragraph} max-w-[470px] mt-5`}>
          Tailor AI to your business needs. Simple, effective, and personalized – we're designed to understand your challenges and enhance your digital strategy.<span className="block md:inline"> Let's build smarter solutions together.</span>
        </p>
      </div>

      <div className={`flex-1 flex ${styles.flexCenter} md:my-0 my-10 relative`}>
        <a href="https://azui.io/login">
          <img src={robot} alt="billing" className="w-[100%] h-[100%] relative z-[5]" />
        </a>
        {/* gradient start */}
        <div className="absolute z-[0] w-[40%] h-[35%] top-0 pink__gradient" />
        <div className="absolute z-[1] w-[80%] h-[80%] rounded-full white__gradient bottom-40" />
        <div className="absolute z-[0] w-[50%] h-[50%] right-20 bottom-20 blue__gradient" />
        {/* gradient end */}
      </div>

      {/* <div className={`ss:hidden sm:hidden mt-[-320px] mb-[120px] ${styles.flexCenter}`}>
        <GetStarted />
      </div> */}
    </section>
  );
};

export default Hero;
