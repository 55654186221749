import { Route, Routes } from "react-router-dom";

import Home from "./Pages/Home";
import Mainlayout from "./Layouts/MainLayout";
import TermsAndCondition from "./Pages/TermsAndCondition";
import Privacy from "./Pages/Privacy";

function App() {
  return (
    <>
      <Routes>
        <Route path='/' element={<Home />} />

        <Route element={<Mainlayout />}>
          <Route path='/privacy' element={<Privacy />} />
          <Route path='/terms&condition' element={<TermsAndCondition />} />
        </Route>
      </Routes>
    </>
  );
}

export default App;
