import React from "react";
import privacy from "../style";
import "../index.css";

const Privacy = () => {
  return (
    <>
      <h1 className="privacy-text">Privacy Policy</h1>
      <div className="privacy-contemt">
        <div>
          <h1 className="privacy-content-title">Introduction and Scope</h1>
          <p>
            This Privacy Policy ("Policy") outlines how Azui, operated by SEVI
            Holdings Limited ("SEVI Holdings"), a company registered in New
            Zealand, collects, uses, and protects personal information from
            users ("User" or "Users") of the Azui platform. This Policy applies
            to all personal data processed in connection with the Azui platform,
            regardless of whether the data subject is located within the
            European Union (EU) or European Economic Area (EEA). By using the
            Azui platform, Users consent to the collection, use, and disclosure
            of their personal information as outlined in this Policy.
          </p>
        </div>
        <div>
          <h1 className="privacy-content-title">Information Collected</h1>
          <p>
            Azui collects personal information from Users when they create an
            account on the platform, including their name, email address, and
            billing information. Additionally, Azui may collect information
            about Users' use of the platform such as their IP address, browser
            type, operating system, and any other data required to provide the
            platform's services.{" "}
          </p>
        </div>
        <div>
          <h1 className="privacy-content-title">
            Use of User Profile Data by AI Models
          </h1>
          <p>
            Azui uses artificial intelligence (AI) models to provide
            personalised services to our users. These AI models utilise the
            profile data provided by users to generate content specific to them
            and their team. The types of user profile data used may include, but
            are not limited to, user name, job title, business information, and
            other relevant details provided during account creation or updated
            in the user profile. Please note that while our AI models use this
            information to provide personalised services, they do not train
            their models based on this usage. The third-party AI models we
            utilise do not have access to store or use this data for their own
            purposes.
          </p>
          <p>
            The user profile data is processed solely within the Azui platform
            and is used exclusively for the purpose of providing the services
            outlined in our Terms and Conditions. We do not share this data with
            any third parties without explicit consent from the user, except as
            necessary to provide our services or as required by law.{" "}
          </p>
          <p>
            Users have control over their profile data at all times. You can
            update your profile information through your account settings on the
            Azui platform. If you wish to request further information about how
            we use your profile data with our AI models, or if you want to
            object to such processing, please contact us.
          </p>
          <p>
            Email Requests to Bots: Users may interact with our bots via a
            generic email address. Please note that this inbox is not monitored
            by human staff. All requests sent to this address are automatically
            deleted after 7 days as part of our commitment to data minimization
            and privacy.
          </p>
        </div>

        <div>
          <h1 className="privacy-content-title">Lawful Basis for Processing</h1>
          <h1>
            Azui processes Users' personal data based on the following lawful
            grounds under the General Data Protection Regulation (GDPR)
          </h1>
          <ol className="bullet-points">
            <li>
              Consent: When Users provide their consent for the collection and
              processing of their personal information for specific purposes,
              such as marketing communications;
            </li>
            <li>
              Performance of a contract: When Users enter into a contract with
              Azui by using the platform, and the processing of personal data is
              necessary to fulfill the terms of the contract;
            </li>

            <li>
              Legitimate interests: When the processing of personal data is
              necessary for Azui's legitimate interests, such as improving the
              platform, analyzing usage patterns, ensuring security, and
              utilizing OpenAI API models that do not train based on user data;
            </li>
            <li>
              Legal obligations: When Azui is required to process personal data
              to comply with legal obligations, such as tax and financial
              reporting requirements.
            </li>
          </ol>
        </div>

        <div>
          <h1 className="privacy-content-title">Use of Information</h1>
          <h1>
            Azui may use Users' personal information for the following purposes:
          </h1>
          <ol className="bullet-points">
            <li>To provide and maintain the platform;</li>
            <li>To process payments and invoices;</li>
            <li>
              To communicate with Users about their account and platform
              updates;
            </li>
            <li>To analyze and improve the platform and User experience;</li>
            <li>
              To comply with legal obligations and protect Azui's rights and
              interests.
            </li>
          </ol>
        </div>

        <div>
          <h1 className="privacy-content-title">Data Protection Officer</h1>
          <p>
            If required under the GDPR, Azui will appoint a Data Protection
            Officer (DPO) and provide their contact information in this Policy.
            The DPO will be responsible for overseeing Azui's data protection
            strategy and ensuring compliance with the GDPR.
          </p>
        </div>

        <div>
          <h1 className="privacy-content-title">Data Subject Rights</h1>
          <h1>
            Under the GDPR, Users located in the EU or EEA have the following
            rights concerning their personal data:
          </h1>
          <ol className="bullet-points">
            <li>
              Right to access: Users have the right to request access to their
              personal data held by Azui and receive a copy of that data.
            </li>
            <li>
              Right to rectification: Users have the right to request the
              correction of any inaccurate or incomplete personal data held by
              Azui.
            </li>
            <li>
              Right to erasure ('right to be forgotten'): Users have the right
              to request the deletion of their personal data held by Azui,
              subject to certain exceptions.
            </li>
            <li>
              Right to restrict processing: Users have the right to request a
              restriction on the processing of their personal data in specific
              circumstances.
            </li>
            <li>
              Right to data portability: Users have the right to receive their
              personal data in a structured, commonly used, and machine-readable
              format and transmit that data to another controller.
            </li>
            <li>
              Right to object: Users have the right to object to the processing
              of their personal data for direct marketing purposes or when
              processing is based on legitimate interests.
            </li>
            <li>
              Right to withdraw consent: If processing is based on consent,
              Users have the right to withdraw their consent at any time,
              without affecting the lawfulness of processing based on consent
              before its withdrawal.{" "}
            </li>
          </ol>
        </div>

        <div>
          <h1 className="privacy-content-title">
            To exercise any of these rights, Users may contact Azui at
            contact@azui.io.
          </h1>
          <h1>Data Transfers</h1>
          <p>
            Azui may transfer personal data outside the European Economic Area
            (EEA) to third-party service providers who assist with the operation
            of the platform or provide related services. When transferring
            personal data outside the EEA, Azui will ensure appropriate
            safeguards are in place to protect the data, such as the use of
            Standard Contractual Clauses approved by the European Commission or
            transferring data to countries that have been deemed to provide an
            adequate level of protection.
          </p>
        </div>

        <div>
          <h1 className="privacy-content-title">Data Retention</h1>
          <p>
            Azui will retain Users' personal information for as long as
            necessary to provide the platform and fulfill the purposes outlined
            in this Policy. Azui may also retain and use Users' personal
            information to comply with legal obligations, resolve disputes, and
            enforce agreements. Personal data will be deleted or anonymized when
            it is no longer required for these purposes.
          </p>
        </div>

        <div>
          <h1 className="privacy-content-title">Cookie Policy</h1>
          <p>
            Azui uses cookies and other tracking technologies to improve the
            platform's functionality, analyze usage patterns, and tailor content
            to Users' preferences. A separate cookie policy or a dedicated
            section within this Privacy Policy will disclose the types of
            cookies used, their purposes, and how Users can manage their cookie
            preferences. Azui will obtain consent from Users for the use of
            non-essential cookies, as required under the EU ePrivacy Directive.
          </p>
        </div>

        <div>
          <h1 className="privacy-content-title">Data Security</h1>
          <p>
            Azui takes reasonable measures, including technical, organizational,
            and administrative safeguards, to protect Users' personal
            information from unauthorized access, use, or disclosure. However,
            no method of transmission over the internet or electronic storage is
            completely secure, and Azui cannot guarantee the absolute security
            of Users' personal information.
          </p>
        </div>
        <div>
          <h1 className="privacy-content-title">Data Breach Notification</h1>
          <p>
            In the event of a personal data breach, Azui will promptly assess
            the risk to Users' rights and freedoms. If the breach is likely to
            result in a high risk to Users, Azui will notify the affected Users
            without undue delay. Azui will also notify the relevant supervisory
            authority within 72 hours of becoming aware of the breach unless the
            breach is unlikely to result in a risk to Users' rights and
            freedoms.
          </p>
        </div>

        <div>
          <h1 className="privacy-content-title">Access and Correction</h1>
          <p>
            Users may access and update their personal information by logging
            into their account on the Azui platform. Users may also contact Azui
            at admin@azui.io to request access or correction of their personal
            information.
          </p>
        </div>

        <div>
          <h1 className="privacy-content-title">Consent Management</h1>
          <p>
            Azui will implement mechanisms to obtain, manage, and document
            consents from Users, as required under the GDPR. Users will have the
            ability to provide, withdraw, or modify their consent for specific
            processing activities at any time.
          </p>
        </div>

        <div>
          <h1 className="privacy-content-title">Changes to Policy</h1>
          <p>
            Azui reserves the right to modify or replace this Policy at any time
            without prior notice. Users are responsible for regularly reviewing
            the Policy to stay informed of any changes. Continued use of the
            Azui platform after any modifications to the Policy shall constitute
            User's consent to such changes.
          </p>
        </div>

        <div>
          <h1 className="privacy-content-title">
            Third-Party Links and Services
          </h1>
          <p>
            The Azui platform may contain links to third-party websites or
            services that are not operated by Azui. This Privacy Policy does not
            apply to these third-party services, and Azui is not responsible for
            their privacy practices. Users are encouraged to review the privacy
            policies of any third-party services they interact with.{" "}
          </p>
          <p>
            Third-Party AI Models: We utilise third-party artificial
            intelligence (AI) models to provide some of our services. These AI
            models may have access to certain data you provide us. The data
            these AI models have access to is used solely for the purpose of
            providing the services and is not used by the third-party for their
            own purposes."
          </p>
        </div>

        <div>
          <h1 className="privacy-content-title">Children's Privacy</h1>
          <p>
            The Azui platform is not intended for use by children under the age
            of 16. Azui does not knowingly collect personal information from
            children under 16. If Azui becomes aware that it has collected
            personal information from a child under the age of 16, it will take
            steps to delete that information promptly.
          </p>
        </div>

        <div>
          <h1 className="privacy-content-title">Complaints</h1>
          <p>
            Users who believe that Azui has not complied with the GDPR or this
            Privacy Policy have the right to lodge a complaint with their local
            data protection authority or the supervisory authority in the
            jurisdiction where Azui is established.
          </p>
        </div>

        <div>
          <h1 className="privacy-content-title">Updates to Privacy Policy</h1>
          <p>
            Azui may update this Privacy Policy from time to time in response to
            changes in legal, regulatory, or operational requirements. Users
            will be notified of any significant changes, and the updated Privacy
            Policy will be posted on the Azui platform with an updated
            "Effective Date." Users are encouraged to review the Privacy Policy
            periodically to stay informed about Azui's privacy practices.
          </p>
        </div>

        <div>
          <h1 className="privacy-content-title">Contact Us</h1>
          <p>
            If you have any questions or concerns about this Policy, the Azui
            platform, or your rights under the GDPR, please contact us at
            admin@azui.io.
          </p>
        </div>
      </div>
    </>
  );
};

export default Privacy;
