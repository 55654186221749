import React from "react";
import "../index.css";

const TermsAndCondition = () => {
  return (
    <>
      <h1 className="terms-and-condition-text">Terms & Conditions</h1>
      <div className="terms-and-condition-contemt">
        <h3>Introduction</h3>
        <p>
          These Terms and Conditions ("Terms") govern the use of the Azui
          platform, operated by SEVI Holdings Limited ("SEVI Holdings"), a
          company registered in New Zealand, trading as Azui. By accessing and
          using the Azui platform, you ("User") agree to be bound by these Terms
          and our Privacy Policy. If you disagree with any part of these Terms,
          please do not use the Azui platform.
        </p>
      </div>
      <div className="terms-and-condition-contemt">
        <h3>Account Registration</h3>
        <p>
          To access and use the Azui platform, Users must create an account by
          providing accurate and complete information. Users are responsible for
          maintaining the confidentiality of their account credentials and are
          liable for any activities that occur under their account. Account
          Registration and Verification To access and use the Azui platform,
          users must create an account by providing accurate and complete
          information. After registering and verifying their email address,
          there may be an additional manual account verification step conducted
          by a system administrator. This is a security measure designed to
          confirm the authenticity of our users. Please note that until this
          step is completed, full access to certain features or areas of the
          platform may be restricted. Users are responsible for maintaining the
          confidentiality of their account credentials and are liable for any
          activities that occur under their account.
        </p>
      </div>
      <div className="terms-and-condition-contemt">
        <h3>Pricing and Subscription</h3>
        <p>
          Azui offers different subscription plans with varying features and
          prices. The specific details of each plan, including what is included,
          the cost, and the billing cycle (monthly, annually, etc.), are made
          available to users once they have created a free account. Once a user
          has upgraded to a paid subscription plan, they will not be able to
          revert back to a free account. Users can upgrade or downgrade between
          paid subscription plans by contacting us. Any changes will take effect
          from the next billing cycle. Payments are due at the start of each
          billing cycle. If a payment fails, we may temporarily suspend access
          to our services until payment is successfully made. We do not offer
          refunds for partial use or non-use of our services during a billing
          cycle. In addition to our standard subscription plans, Azui also
          offers custom services such as consulting and bespoke AI tool
          creation. The pricing for these services is determined on a
          case-by-case basis depending on the specific requirements of the user.
          Users interested in these services should contact us directly to
          discuss their needs and receive a quote.
        </p>
      </div>
      <div className="terms-and-condition-contemt">
        <h1>User Conduct</h1>
        <p>
          Users agree to comply with all applicable laws and regulations while
          using the Azui platform. Users are prohibited from engaging in any
          unlawful, harmful, or offensive activities; infringing on the rights
          of others; interfering with the operation of the Azui platform; using
          the Azui platform for fraudulent purposes; or violating third-party
          service terms. Azui reserves the right to monitor user activity,
          investigate alleged violations of these Terms, take appropriate action
          including removing content or suspending accounts, or cooperating with
          law enforcement authorities.
        </p>
      </div>
      <div className="terms-and-condition-contemt">
        <h1>Intellectual Property Rights</h1>
        <p>
          All intellectual property rights related to the Azui platform are
          owned by SEVI Holdings or its licensors. Users are granted a limited
          license to access and use the Azui platform in accordance with these
          Terms. User-Generated Content
        </p>
        <p>
          Users retain ownership of content they create using the Azui platform.
          Users represent that they have necessary rights to use and share such
          content without infringing on third-party rights.
        </p>

        <p>
          Azui reserves the right to remove any User-Generated Content that
          violates these Terms or applicable laws. Users are solely responsible
          for any claims arising from their User-Generated Content.
        </p>
      </div>
      <div className="terms-and-condition-contemt">
        <h1>Data Protection and Privacy</h1>
        <p>
          Azui is committed to protecting user data in accordance with our
          Privacy Policy. Users are responsible for ensuring that any data they
          provide to Azui complies with applicable data protection laws.
        </p>
      </div>
      <div className="terms-and-condition-contemt">
        <h1>API Usage</h1>
        <p>
          If the Azui platform offers API access, Users agree to comply with
          additional terms related to API usage. Azui reserves the right to
          monitor API usage and suspend access if Users violate these Terms
        </p>
      </div>
      <div className="terms-and-condition-contemt">
        <h1>Third-Party Integrations</h1>
        <p>
          The Azui platform may integrate with third-party services. Users
          acknowledge that their use of such services is subject to the terms of
          the respective third-party providers. Azui is not responsible for
          these third-party services.
        </p>
      </div>
      <div className="terms-and-condition-contemt">
        <h1>Service Availability and Support</h1>
        <p>
          Azui strives to maintain high service availability but does not
          guarantee uninterrupted or error-free access. In the event of service
          interruption, Azui will make reasonable efforts to restore access as
          soon as possible. There may be periods of downtime due to maintenance,
          updates, or other factors beyond our control.
        </p>
      </div>
      <div className="terms-and-condition-contemt">
        <h1>Fair Use Policy</h1>
        <p>
          Azui implements a Fair Use Policy to ensure all users enjoy optimal
          service levels. We reserve the right to manage or limit access in
          instances where a user's consumption significantly exceeds the average
          of other customers. Such excessive use may risk undermining the
          stability, performance, or availability of our services for other
          users.
        </p>
      </div>
      <div className="terms-and-condition-contemt">
        <h1>Access</h1>
        <p>
          Azui reserves the right to remove free user access at any time without
          prior notice.
        </p>
      </div>
      <div className="terms-and-condition-contemt">
        <h1>Disclaimers and Warranties</h1>
        <p>
          The Azui platform, including its AI tools, is provided "as is" without
          any warranties. While we strive to provide accurate information
          through these tools, we do not warrant their accuracy or suitability
          for any specific purpose. Users should consult with a qualified
          professional for advice tailored to their specific circumstances.
        </p>
      </div>
      <div className="terms-and-condition-contemt">
        <h1>Limitation of Liability</h1>
        <p>
          In no event shall Azui be liable for any damages arising from the use
          of the Azui platform or its AI tools, including damages resulting from
          reliance on information provided by our AI tools or decisions made
          based on such information.
        </p>
      </div>
      <div className="terms-and-condition-contemt">
        <h1>Indemnification</h1>
        <p>
          Users agree to indemnify and hold harmless Azui from any claims
          arising out of their use of the Azui platform or its AI tools,
          including claims relating to reliance on information provided by our
          AI tools or decisions made based on such information.
        </p>
      </div>
      <div className="terms-and-condition-contemt">
        <h1>Dispute Resolution</h1>|
        <p>
          Any disputes arising from these Terms shall be resolved through
          negotiation, mediation, or arbitration before resorting to litigation.
        </p>
      </div>
      <div className="terms-and-condition-contemt">
        <h1>Governing Law</h1>
        <p>
          These Terms shall be governed by the laws of New Zealand, and any
          disputes arising from these Terms shall be subject to the exclusive
          jurisdiction of the courts of New Zealand.
        </p>
      </div>
      <div className="terms-and-condition-contemt">
        <h1>Changes to Terms</h1>
        <p>
          Azui reserves the right to modify these Terms at any time without
          prior notice. Continued use of the Azui platform after any
          modifications constitutes User's consent to such changes.
        </p>
      </div>
      <div className="terms-and-condition-contemt">
        <h1>Entire Agreement</h1>
        <p>
          These Terms constitute the entire agreement between Azui and Users and
          supersede all prior agreements relating to the subject matter of these
          Terms.
        </p>
      </div>
      <div className="terms-and-condition-contemt">
        <h1>Waiver and Severability</h1>
        <p>
          The failure of Azui to enforce any right or provision of these Terms
          shall not be deemed a waiver of such right or provision. If any
          provision of these Terms is held to be invalid or unenforceable, the
          remaining provisions will remain in effect.
        </p>
      </div>

      <div className="terms-and-condition-contemt">
        <h1>Contact Us</h1>
        <p>
          If you have any questions about these Terms, please contact us at
          admin@azui.io
        </p>
      </div>
    </>
  );
};

export default TermsAndCondition;
