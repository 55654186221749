import styles from "../style";
import { logo } from "../assets";
import { footerLinks, socialMedia, contactInformation } from "../constants";
import {NavLink} from "react-router-dom";

const Footer = () => (
  <section className={`${styles.flexCenter} ${styles.paddingY} flex-col`}>
    <div className={`${styles.flexStart} md:flex-row flex-col mb-8 w-full`}>
      <div className="flex-[1] flex flex-col justify-start mr-4">
        <img
          src={logo}
          alt="azui"
          className="w-[366px] h-[132.14px] object-contain mt-[15px]"
        />
        {/* <p className={`${styles.paragraph} mt-4 max-w-[312px]`}>
        Elevate your digital interactions with our AI tools, redefining the way you engage online.
        </p> */}
      </div>

      <div className="flex-[1.5] w-full flex flex-row justify-between flex-wrap md:mt-0 mt-10">
        {footerLinks.map((footerlink) => (
          <div
            key={footerlink.title}
            className={`flex flex-col ss:my-0 my-4 min-w-[150px]`}>
            <h4 className="font-poppins font-medium text-[18px] leading-[27px] text-white">
              {footerlink.title}
            </h4>
            {/*{console.log("footerlink", footerlink)}*/}
            {/*<ul className="list-none mt-4">*/}
            {/*  {footerlink.links.map((link, index) => (*/}
            {/*    <a href={link.link}>*/}
            {/*      <li*/}
            {/*        key={link.name}*/}
            {/*        className={`font-poppins font-normal text-[16px] leading-[24px] text-dimWhite hover:text-secondary cursor-pointer ${*/}
            {/*          index !== footerlink.links.length - 1 ? "mb-4" : "mb-0"*/}
            {/*        }`}>*/}
            {/*        {link.name}*/}
            {/*      </li>*/}
            {/*    </a>*/}
            {/*  ))}*/}
            {/*</ul>*/}
            <div className='mt-4'>
              { footerlink.links.map((link, index) => (
                  <div  className={`font-poppins font-normal text-[16px] leading-[24px] text-dimWhite hover:text-secondary cursor-pointer ${
                          index !== footerlink.links.length - 1 ? "mb-4" : "mb-0"
                          }`} key={index}>
                    <NavLink to={link.link}>{link.name}</NavLink>
                  </div>
              ))}
            </div>
          </div>
        ))}
      </div>
      <div className="flex-[1] flex flex-col justify-start ml-[110px]">
        <h4 className="font-poppins font-medium text-[18px] leading-[27px] text-white mb-4">
          Contact Us
        </h4>
        <div className={`${styles.paragraph} max-w-[312px]`}>
          {Object.values(contactInformation).map((info, index) => (
            <div key={index} className="flex items-center mb-2">
              <span className="mr-2 text-gray-500">
                {/* Add your icon component or class here */}
              </span>
              <img className="mr-2" src={info.icon} alt={info.text} />
              <a href={'mailto:'+info.text} className="text-gray">{info.text}</a>
            </div>
          ))}
        </div>
        {/* <div className="flex flex-row mt-[17px] mr-[2px] ">
        {socialMedia.map((social, index) => (
          <img
            key={social.id}
            src={social.icon}
            alt={social.id}
            className={`w-[21px] h-[21px] object-contain cursor-pointer ${
              index !== socialMedia.length - 1 ? "mr-6" : "mr-0"
            }`}
            onClick={() => window.open(social.link)}
          />
        ))}
      </div> */}
      </div>
    </div>

    <div className="w-full flex justify-between items-center md:flex-row flex-col pt-6 border-t-[1px] border-t-[#3F3E45]">
      <p className="font-poppins font-normal text-center text-[18px] leading-[27px] text-white">
        Copyright Ⓒ 2024 Azui. All Rights Reserved.
      </p>
    </div>
  </section>
);

export default Footer;
