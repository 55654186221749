import {
  people01,
  people02,
  people03,
  facebook,
  instagram,
  linkedin,
  twitter,
  airbnb,
  ha,
  gor,
  dropbox,
  send,
  shield,
  star,
  phone,
  email,
  location,
} from "../assets";

export const navLinks = [
  {
    id: "home",
    title: "Home",
  },
  {
    id: "clients",
    title: "Reviews",
  },
  {
    id: "https://azui.io/login",
    title: "Login",
  },
  {
    id: "https://azui.io/register",
    title: "Register",
  },
];

export const features = [
  {
    id: "feature-1",
    icon: star,
    title: "Generative AI Tools",
    content:
      "Our demo platform shows examples of how you can create capacity with AI",
    href: "/#product",
  },
  {
    id: "feature-2",
    icon: shield,
    title: "Custom Solutions",
    content: "Build solutions aligned with your brand and business model",
    href: "/#tools",
  },
  {
    id: "feature-3",
    icon: send,
    title: "Consulting",
    content:
      "Our team can work with you to find the best OpenAI use cases for your business",
    href: "/#try",
  },
];

export const feedback = [
  {
    id: "feedback-1",
    content:
      "We love how the AI knows our roles to make advice more personalized without prompting",
    name: "Sarah",
    title: "HR Leader",
    // img: people01,
  },
  {
    id: "feedback-2",
    content:
      "As a growing business it's been great to get our team up and running with AI tools without the hefty per user fees",
    name: "Simon",
    title: "Business Owner",
    // img: people02,
  },
  {
    id: "feedback-3",
    content:
      "Azui created a solution for a critical process that has reduced our reliance on freelancers by over 50%.",
    name: "Kim",
    title: "Global Business Lead",
    // img: people03,
  },
];

export const stats = [
  {
    id: "stats-1",
    title: "Completions",
    value: "1m+",
  },
  {
    id: "stats-2",
    title: "More Capacity Unlocked",
    value: "50%",
  },
  {
    id: "stats-3",
    title: "Custom",
    value: "100%",
  },
];

export const footerLinks = [
  {
    title: "Useful Links",
    links: [
      {
        name: "Home",
        link: "/",
      },
    ],
  },
  {
    title: "Pages",
    links: [
      {
        name: "Privacy Policy",
        link: "/privacy",
      },
      {
        name: "Terms & Conditions",
        link: "/terms&condition",
      },
    ],
  },
];

export const socialMedia = [
  {
    id: "social-media-1",
    icon: instagram,
    link: "https://www.instagram.com/",
  },
  {
    id: "social-media-2",
    icon: facebook,
    link: "https://www.facebook.com/",
  },
  {
    id: "social-media-3",
    icon: twitter,
    link: "https://www.twitter.com/",
  },
  {
    id: "social-media-4",
    icon: linkedin,
    link: "https://www.linkedin.com/",
  },
];

export const contactInformation = {
  // address: {
  //   text: "123 Main Street, Cityville",
  //   icon: location, // Replace with your address icon class
  // },
  // phone: {
  //   text: "+1 (123) 456-7890",
  //   icon: phone, // Replace with your phone icon class
  // },
  email: {
    text: "sales@azui.io",
    icon: email, // Replace with your email icon class
  },
};

export const clients = [
  {
    id: "client-1",
    logo: gor,
  },
  // {
  //   id: "client-2",
  //   logo: airbnb,
  // },
  {
    id: "client-3",
    logo: ha,
  },
  // {
  //   id: "client-4",
  //   logo: dropbox,
  // },
];
